/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageProps, Post } from '../types'
import { PostExcert } from '../components/post-excerpt'

type DataProps = PageProps<{
  markdownRemark: Post
}>

const PageTemplate = ({ data, location }: DataProps): JSX.Element => {
  const post = data.markdownRemark

  return (
    <Layout
      pages={data.pages}
      location={location}
      site={data.site}
      nav={[
        {
          href: `/pages/`,
          title: 'Pages'
        },
        {
          href: data.markdownRemark.fields.slug,
          title: data.markdownRemark.frontmatter.title
        }
      ]}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <PostExcert post={post} site={data.site} isPost={false} />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          summary
        }
      }
    }
    pages: allFile(
      filter: {
        sourceInstanceName: { eq: "pages" }
        childMarkdownRemark: { id: { glob: "*" } }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        categories
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
